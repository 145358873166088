import revive_payload_client_m__D8QwDoJNm4a4qRKEMGPq1P_UyhMGBGeDAE_z_SNg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DSUMRCfLa0mVn3trWngNDy_Cjvu87_tI3qAU1QI0iB0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nYvD_2TkHea93qjWgDwvKJa8_hq4E8x9sy7PI12D_nU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_94Z85Lf2O8GsWI_zMcgdBGtiUWsLLwSpS071_9C2Io0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_MtwZh8JX4mK3r7Q65Ee_eSxossXv1qOojpvU_B_TYCk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RVzH1zGRuck_TBl2L38I9P3YBgONsBNRz7oK9SLHM0Y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_r6fJFBdRDauHNM4RzUGngRwAPqTHIzo5Q1zapzl0jsM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_BkOsQ_I7dDNxpmkZHQ_NiB68SyJLyLlZ_znbvXkrNYM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_fvypNLixVnk0MeARJkRSPrPBUwWSdAiypNwz5PywcIU from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@9.11.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1__5eff3ee9ff15db8aeddb586cacda9df8/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import colors_4Vb8gHOvXJs7u4L5gVW4UeE7LfWQlLeKD_ra910irzM from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@3.0.2_@babel+parser@7.27.0_db0@0.3.1_better-sqlite3@11.9.1__embla-carousel@8.5_d26b8b38d17a768a844f91ec056ad12d/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_TKvV8CmBx_hAdoMc7U_h5CNcYrFD0Fdm6CXCug2blpc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.3_@types+node@22.13.14_jiti@2.4.2_lightningcs_3cdc2a67a01030d631da9490adb45634/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_m__D8QwDoJNm4a4qRKEMGPq1P_UyhMGBGeDAE_z_SNg,
  unhead_DSUMRCfLa0mVn3trWngNDy_Cjvu87_tI3qAU1QI0iB0,
  router_nYvD_2TkHea93qjWgDwvKJa8_hq4E8x9sy7PI12D_nU,
  _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM,
  payload_client_94Z85Lf2O8GsWI_zMcgdBGtiUWsLLwSpS071_9C2Io0,
  navigation_repaint_client_MtwZh8JX4mK3r7Q65Ee_eSxossXv1qOojpvU_B_TYCk,
  check_outdated_build_client_RVzH1zGRuck_TBl2L38I9P3YBgONsBNRz7oK9SLHM0Y,
  chunk_reload_client_r6fJFBdRDauHNM4RzUGngRwAPqTHIzo5Q1zapzl0jsM,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_BkOsQ_I7dDNxpmkZHQ_NiB68SyJLyLlZ_znbvXkrNYM,
  sentry_client_fvypNLixVnk0MeARJkRSPrPBUwWSdAiypNwz5PywcIU,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  colors_4Vb8gHOvXJs7u4L5gVW4UeE7LfWQlLeKD_ra910irzM,
  plugin_TKvV8CmBx_hAdoMc7U_h5CNcYrFD0Fdm6CXCug2blpc,
  plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs
]