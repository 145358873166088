
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93kxjr4BRGYu8PjICw6eKIYYGNlIv1T0FK9GFhjf_45hT6QMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as _91tag_933L2McCxducMWfO7xqsqurGESxR4a0uisv1vyNFJjttIMeta } from "/opt/buildhome/repo/pages/posts/tag/[tag].vue?macro=true";
import { default as _91tag_93yNIm1OxLNjtfU13XcasMNoOwDd_458WcSmmhPWk_7E4vgMeta } from "/opt/buildhome/repo/pages/moments/tag/[tag].vue?macro=true";
import { default as _91post_93DWH27E3NHZxN6xVheZLZWFcWvxawG2PfyjGUEXSMGz4Meta } from "/opt/buildhome/repo/pages/posts/[year]/[post].vue?macro=true";
import { default as _91moment_93lD8_45odNP4QPxaEkaguWzVpzFTOyu1by_zdIAAs8MqjMMeta } from "/opt/buildhome/repo/pages/moments/[year]/[moment].vue?macro=true";
import { default as component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__12f4f96fdb8d1b8bc6512f7add464551/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "posts-tag-tag",
    path: "/posts/tag/:tag()",
    component: () => import("/opt/buildhome/repo/pages/posts/tag/[tag].vue")
  },
  {
    name: "moments-tag-tag",
    path: "/moments/tag/:tag()",
    component: () => import("/opt/buildhome/repo/pages/moments/tag/[tag].vue")
  },
  {
    name: "posts-year-post",
    path: "/posts/:year()/:post()",
    component: () => import("/opt/buildhome/repo/pages/posts/[year]/[post].vue")
  },
  {
    name: "moments-year-moment",
    path: "/moments/:year()/:moment()",
    component: () => import("/opt/buildhome/repo/pages/moments/[year]/[moment].vue")
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/archives",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/2011-spring-festival",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2011/2011-spring-festival",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/hereafter",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2011/hereafter",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/sc2-tactics-marine-rush",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2011/sc2-tactics-marine-rush",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/sc2-growth",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/sc2-growth",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/sqlserver-cannot-delete",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/sqlserver-cannot-delete",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/win8-disk-full-usage",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/win8-disk-full-usage",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/filename-changes",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/filename-changes",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/eclipse-not-display-code-hint",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/eclipse-not-display-code-hint",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/several-intent-filters-in-one-activity",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/several-intent-filters-in-one-activity",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/haoest-new-website",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/haoest-new-website",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/website-change-and-seo",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/website-change-and-seo",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/using-of-android-cleartaskonlaunch",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/using-of-android-cleartaskonlaunch",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/set-eclipse-juno-for-android",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/set-eclipse-juno-for-android",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/how-to-modify-windows-library-icon",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/how-to-modify-windows-library-icon",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/android-wifi-connection",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/android-wifi-connection",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/csharp-get-ipaddress",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/csharp-get-ipaddress",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/android-unit-test",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/android-unit-test",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/brief-summary",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/brief-summary",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/jiankongbao",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/jiankongbao",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/summer-summary",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/summer-summary",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/sqlite-error-no-such-table",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/sqlite-error-no-such-table",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/haoest-happy-moon-festival",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/haoest-happy-moon-festival",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/ubuntu-mp3-messy-code",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/ubuntu-mp3-messy-code",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/could-not-load-file-or-assembly",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/could-not-load-file-or-assembly",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/type-initialization-exception",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/type-initialization-exception",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/try-mono-for-android",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/try-mono-for-android",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/snaps-to-device-pixels",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2012/snaps-to-device-pixels",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/2013-winter-holiday-plan",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/2013-winter-holiday-plan",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/use-wds-to-cover-your-house-with-wifi",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/use-wds-to-cover-your-house-with-wifi",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/server-migration-finished",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/server-migration-finished",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/uninstall-orbit-plugin-for-chrome",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/uninstall-orbit-plugin-for-chrome",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/sublime-text-2-supergood-code-editor",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/sublime-text-2-supergood-code-editor",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/tomorrow-go-back-to-school-to-finish-my-winter-life",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/tomorrow-go-back-to-school-to-finish-my-winter-life",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/forbid-index-of-your-host",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/forbid-index-of-your-host",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/self-reflection-about-lazy-and-friendship",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/self-reflection-about-lazy-and-friendship",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/win8-app-develop-study-design-logo",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/win8-app-develop-study-design-logo",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/post-views-for-wordpress-themes",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/post-views-for-wordpress-themes",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/icbc-plugin-cause-blank-line-in-chrome",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/icbc-plugin-cause-blank-line-in-chrome",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/set-win8-explorer-to-my-computer",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/set-win8-explorer-to-my-computer",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/fix-chinese-url-in-wordpress-in-iis",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2013/fix-chinese-url-in-wordpress-in-iis",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/2014-beginning",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2014/2014-beginning",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/at-the-crossroads-of-life",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2014/at-the-crossroads-of-life",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/webkit-white-spacenowrap",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2014/webkit-white-spacenowrap",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/hadb-me-back",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/hadb-me-back",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/use-custom-domain-for-hexo-on-github",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/use-custom-domain-for-hexo-on-github",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/move-duoshuo-comments",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/move-duoshuo-comments",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/2015-start-working",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/2015-start-working",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/windows-close-monitor-tool",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/windows-close-monitor-tool",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/entity-framework-code-first-two-foreign-keys-from-same-table",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/entity-framework-code-first-two-foreign-keys-from-same-table",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/show-computer-on-desktop-in-windows-server",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/show-computer-on-desktop-in-windows-server",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/about-paipai",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/about-paipai",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/authenticationmanager-signout-not-working",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/authenticationmanager-signout-not-working",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/written-at-the-end-of-march",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/written-at-the-end-of-march",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/good-bye-mylo",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/good-bye-mylo",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/fix-fixed-bug-in-ios-when-call-virtual-keyboards",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/fix-fixed-bug-in-ios-when-call-virtual-keyboards",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/web-deploy",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/web-deploy",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/good-bye-newegg",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/good-bye-newegg",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/vs2015-installer-not-work-in-windows-xp",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/vs2015-installer-not-work-in-windows-xp",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/change-domain-in-weixin",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/change-domain-in-weixin",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/the-end-of-2015",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2015/the-end-of-2015",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/plan-b",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/plan-b",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/personal-summary",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/personal-summary",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/hexo-with-travis-ci",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/hexo-with-travis-ci",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/asp-net-core-first-experience",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/asp-net-core-first-experience",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/bad-request-invalid-hostname",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/bad-request-invalid-hostname",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/publishing-asp-net-core-to-iis-with-web-deploy-using-visual-studio",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/publishing-asp-net-core-to-iis-with-web-deploy-using-visual-studio",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/redis-windows-sentinel",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/redis-windows-sentinel",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/upgrade-to-net-core-app-1-0-1-problem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/upgrade-to-net-core-app-1-0-1-problem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/aliyun-cdn-not-support-sni",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/aliyun-cdn-not-support-sni",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/nas",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/nas",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/asp-net-core-ef-sqlite-add-foreign-key-to-exist-table",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/asp-net-core-ef-sqlite-add-foreign-key-to-exist-table",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/small-black-dog",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/small-black-dog",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/annual-review-2016",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2016/annual-review-2016",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-1-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-1-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-2-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-2-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-3-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-3-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-4-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-4-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-5-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-5-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-6-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-6-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-7-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-7-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-of-waiting-for-baby-born",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-of-waiting-for-baby-born",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-18-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-18-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-20-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-20-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/week-21-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/week-21-of-2017",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/rumengling",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/rumengling",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170607",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170607",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170613",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170613",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170614",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170614",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170617",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170617",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170618",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170618",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170703",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170703",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170822",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170822",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20170916",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20170916",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/using-ghost",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/using-ghost",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thief-came-into-my-house",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/thief-came-into-my-house",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/story-of-xiaohei",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/story-of-xiaohei",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/celebrate-19th-national-congress",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/celebrate-19th-national-congress",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20171026",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20171026",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20171031",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/diary-20171031",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/transfer-blog-to-aliyun-docker",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/transfer-blog-to-aliyun-docker",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/wxapp-ssl-error",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2017/wxapp-ssl-error",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/start-of-2018",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/start-of-2018",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/about-lonely",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/about-lonely",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20180129",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20180129",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20180211",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20180211",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20180301",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20180301",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20180407",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20180407",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/dockerfile-maven",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/dockerfile-maven",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/frontend-components-and-docker-deploy",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/frontend-components-and-docker-deploy",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20180419",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20180419",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20180420",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20180420",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/windows-storage-spaces-raid1-degraded",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/windows-storage-spaces-raid1-degraded",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20180607",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20180607",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20181009",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20181009",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20181018",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20181018",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/dinner-with-old-friends-20181023",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/dinner-with-old-friends-20181023",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/about-dream",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/about-dream",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20181105",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20181105",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20181109",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20181109",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20181210",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/diary-20181210",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/good-bye-2018",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2018/good-bye-2018",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/hello-2019",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/hello-2019",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/ghost-docker-mail-config",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/ghost-docker-mail-config",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/about-work",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/about-work",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20190127",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/diary-20190127",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/hello-osmo-pocket",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/hello-osmo-pocket",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/new-blog-theme",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/new-blog-theme",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thoughts-about-value",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/thoughts-about-value",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20190310",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/diary-20190310",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20190313",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/diary-20190313",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20190314",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/diary-20190314",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20190315",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/diary-20190315",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thoughts-about-the-future",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/thoughts-about-the-future",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thoughts-about-the-feture-email",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/thoughts-about-the-feture-email",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/audiowave-animation",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/audiowave-animation",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/carpe-diem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/carpe-diem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/an-interesting-thing-in-chongqing",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/an-interesting-thing-in-chongqing",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/speech-of-study-cheche",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/speech-of-study-cheche",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/centos-wire-ieee8021x-config",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/centos-wire-ieee8021x-config",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/empty-your-self",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/empty-your-self",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/solve-pycharm-adding-pipenv-error",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/solve-pycharm-adding-pipenv-error",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/qiniu-ssl-certificate-expire-problem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/qiniu-ssl-certificate-expire-problem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20190810",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/diary-20190810",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/docker-registry-auth-with-same-domain",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/docker-registry-auth-with-same-domain",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/aliyun-k8s-setup",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2019/aliyun-k8s-setup",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/fighting-2020",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/fighting-2020",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20200105",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/diary-20200105",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/batch-edit-acl-for-oss",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/batch-edit-acl-for-oss",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/best-game-red-dead-redemption-2",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/best-game-red-dead-redemption-2",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/iterm2login",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/iterm2login",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/k8s-cert-manager-tls",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/k8s-cert-manager-tls",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/record-of-endoscopy-and-colonoscopy",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/record-of-endoscopy-and-colonoscopy",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/japanese-cuisine-team-building",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/japanese-cuisine-team-building",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/old-friend-dinner",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/old-friend-dinner",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/how-to-become-a-fullstack-developer",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/how-to-become-a-fullstack-developer",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/baixiang-poems",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/baixiang-poems",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/android-webview-picture-cache",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/android-webview-picture-cache",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/devops-gitlab-ci-aliyun-k8s",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2020/devops-gitlab-ci-aliyun-k8s",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/beginning-of-2021",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2021/beginning-of-2021",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/fastboot-failed-remote-operation-not-permitted",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2021/fastboot-failed-remote-operation-not-permitted",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/synology-letsencrypt-multiple-domain-cert-configuration",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2021/synology-letsencrypt-multiple-domain-cert-configuration",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/review-2021-and-look-forward-to-2022",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2022/review-2021-and-look-forward-to-2022",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/use-gitlab-to-deploy-ghost-theme-automatically",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2022/use-gitlab-to-deploy-ghost-theme-automatically",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thoughts-about-life",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2022/thoughts-about-life",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/upgrade-ghost-5-0-to-5-42",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/upgrade-ghost-5-0-to-5-42",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/gitlab-ci-auto-deploy-python-lib",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/gitlab-ci-auto-deploy-python-lib",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/timedrotatingfilehandler-backupcount-problem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/timedrotatingfilehandler-backupcount-problem",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/shanghai-luohu",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/shanghai-luohu",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/use-cloudflare-speed-up-overseas-traffic",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/use-cloudflare-speed-up-overseas-traffic",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/font2svg-solution",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/font2svg-solution",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thoughts-about-growth",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/thoughts-about-growth",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/three-small-things",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2023/three-small-things",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/annual-review-2023",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/annual-review-2023",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20240226",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/diary-20240226",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20240227",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/diary-20240227",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20240229",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/diary-20240229",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/how-to-delete-web-service-of-synology-dsm-7-2",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/how-to-delete-web-service-of-synology-dsm-7-2",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/wordpress-hacked",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/wordpress-hacked",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/get-video-rotation-by-mp4box-js",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/get-video-rotation-by-mp4box-js",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/webvolve-series-events-speech",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/webvolve-series-events-speech",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/diary-20240611",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/diary-20240611",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/subsystem-request-failed-on-channel",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/subsystem-request-failed-on-channel",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/slice-of-mind-20240801",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/slice-of-mind-20240801",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/switch-blog-from-ghost-to-nuxt-content",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/switch-blog-from-ghost-to-nuxt-content",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/guide-for-beginners",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/guide-for-beginners",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thoughts-about-ai",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/thoughts-about-ai",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/travel-notes-of-tailand",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/travel-notes-of-tailand",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/car-leak-experience",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/car-leak-experience",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/managing-containers-in-my-homelab",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2024/managing-containers-in-my-homelab",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/end-of-2024",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2025/end-of-2024",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/change-font-of-vscode-sidebar",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2025/change-font-of-vscode-sidebar",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/deploy-deepseek-r1-for-free",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2025/deploy-deepseek-r1-for-free",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/display-deepseek-r1-thinking",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2025/display-deepseek-r1-thinking",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/thoughts-on-when-to-liberate-taiwan",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/posts/article/2025/thoughts-on-when-to-liberate-taiwan",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  },
  {
    name: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubonmtkts78xa_45U1416ZVuH6SMyhQmnfj2NglE9SLTxxA
  }
]